<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { sleep } from '/~/utils/timer'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.v2.vue'
import { usePurchases } from '/~/composables/purchases'

const emit = defineEmits<{
  (event: 'hide' | 'submit' | 'cancel'): void
}>()

const router = useRouter()
const { unlockGiftCard, selectedPurchaseCard } = usePurchases()

const loading = ref(false)
const unlocked = ref(false)

const cardRoute = computed(() => ({
  hash: `#profile-e-purchase/${(selectedPurchaseCard.value as any).uuid}`,
}))

async function submit() {
  if (loading.value || !selectedPurchaseCard.value) {
    return
  }

  loading.value = true

  await unlockGiftCard((selectedPurchaseCard.value as any).uuid)

  await sleep(800).then(() => {
    emit('submit')
    emit('hide')

    loading.value = false
    unlocked.value = true

    router.push(cardRoute.value)
  })
}

function cancel() {
  emit('cancel')
  emit('hide')
}

const clickToUnlock = computed(() => {
  return `${ui.mobile ? 'Tap' : 'Click'} To Unlock`
})
</script>

<template>
  <div class="flex max-h-full grow flex-col overflow-hidden bg-light">
    <drawer-icon-header
      title="Unlock your ePurchase"
      icon="purchases/padlock"
      :action="cancel"
    />

    <div class="mb-2.5 px-[5px] text-center font-inter text-eonx-neutral-600">
      Once you unlock the ePurchase you will be able to use it for instant
      redemption. Keep in mind that you wont be able to ask for a refund on an
      already unlocked ePurchase.
    </div>

    <div
      class="flex w-full items-center justify-center overflow-y-auto px-5 py-5"
    >
      <button
        :class="[
          !loading && !unlocked && 'bg-opacity-100',
          loading && 'border-success bg-success',
          unlocked && 'border-success bg-success bg-opacity-0',
        ]"
        class="relative flex h-14 w-full max-w-full items-center justify-center overflow-hidden rounded-full border-2 border-solid duration-1000"
        @click="submit"
      >
        <div class="flex items-center justify-center font-bold">
          <span
            :class="[
              !loading && !unlocked && 'text-eonx-neutral-600 opacity-0',
              loading && 'text-green-600 opacity-100',
            ]"
            class="absolute text-green-600 transition-all duration-1000"
          >
            Unlocked!
          </span>
          <span
            :class="[
              !loading && !unlocked && 'text-eonx-neutral-600 opacity-100',
              loading && 'text-green-400 opacity-0',
              unlocked && 'hidden',
            ]"
            class="transition duration-1000"
          >
            {{ clickToUnlock }}
          </span>
        </div>
        <div
          :class="[
            loading || unlocked
              ? '-left-12 translate-x-full'
              : 'translate-x-[5px]',
          ]"
          class="absolute left-0 w-full transition-all duration-1000"
        >
          <div
            :class="[
              loading && 'bg-green-600',
              !unlocked && !loading && 'bg-gray-400',
              unlocked && !loading && 'bg-green-600',
            ]"
            class="flex h-11 w-11 items-center justify-center overflow-hidden rounded-full text-white transition duration-1000"
          >
            <base-icon
              :class="[
                (loading || unlocked) && '-translate-y-[35px]',
                !unlocked && !loading && 'translate-y-0',
              ]"
              class="transform transition duration-1000"
              svg="purchases/padlock-locked"
              size="md"
            />
            <base-icon
              :class="[
                (loading || unlocked) && 'translate-y-0',
                !unlocked && !loading && 'translate-y-[35px]',
              ]"
              class="absolute transform transition duration-1000"
              svg="purchases/padlock-unlocked"
              size="md"
            />
          </div>
        </div>
      </button>
    </div>

    <base-button
      class="-mt-2.5 mb-5 hover:bg-violet-700"
      color="white"
      full-width
      @click="cancel"
    >
      Cancel
    </base-button>
  </div>
</template>
